.step {
  position: relative;

  &.active {
    .step-circle {
      transform: scale3d(1.4, 1.4, 1.4);
      background: $success
    }

    .step-image {
      display: block;
    }
  }
}

.step-circle {
  width: 15px;
  height: 15px;
  background: $gray-300;
  display: block;
  border-radius: 50px;
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
  transform: none;
  transition: transform .15s linear;
  z-index: 999;

  &:hover {
    transform: scale3d(1.4, 1.4, 1.4);
  }
}

.step-image {
  max-width: 100px;
  position: absolute;
  right: -42px;
  top: -90px;
  display: none;
}

.step-text {
  display: none;

  &.active {
    display: block;
  }
}
