// 
// Use this file to write your own custom SCSS.
//
html {
  scroll-behavior: smooth;
}

// Font Size

h1 {
	font-size: 48px;
}

h2 {
	font-size: 37px;
}

h3 {
	font-size: 30;
}


p {
	font-size: 24px;
}


@media (min-width: 992px) {
  h1 {
	font-size: 55px !important;
  }
  p {
	font-size: 19px !important;
  }

  h3 {
	font-size: 38px !important;
  }

}

.aviso {
	font-size: 18px;
}





//Font color

.text-light {
	color: $light !important;
}

.text-info {
	color: $info;
}

// Nav Links

.nav-link:hover {
	color: $secondary !important;
}

.nav-link:active {
	color: $secondary !important;
}

.nav-link:focus {
	color: $secondary !important;
}

// Footer links

.footer {

	a {
		line-height: 2;
		font-size: 18px;
	}

	a:hover {
		color: $secondary !important;
		text-decoration: none;
	}
}

// Linea

hr {
	color: $primary;
	height: 1.4px !important;
	margin: 1.8rem 0;
}


.linea1 {
	color: $secondary;
	
}

.linea-collapse {
	margin: 1.2rem 0 !important;

}


// Boton concacto Servicios

@media (min-width: 992px) {
	.button-position-lg {
     position: absolute;
     bottom: 0 !important;
     left: 0 !important;
  }
}



// Image positioning

.to-back {
	z-index: -2;
}

.to-front {
	z-index: 2;
}




//Button

.btn {
	a:hover {
    	text-decoration: none;
    }
}

.btn-outline-dark {
    color: #000;
    border-color: #000;
    border-radius: 40px;
	padding: 18px 27px;
    font-size: 20px;
}

.btn-outline-dark:hover {
    color: #fff !important;
    background-color: $secondary;
    border-color: $secondary;
}

.btn-outline-dark:active:focus {
    box-shadow: 0 0 0 0.25rem #34bd9159;
}

.btn-outline-dark:active {
    color: #fff !important;
    background-color: $secondary;
    border-color: $secondary;
    transform: translate3d(0,-5px,0) !important;
}

.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem #bdedd8;
}

// Form

.form-control {
    border: 1.5px solid #000;
}



// Image Background

.img-background {
	min-height: 100%;
}

.img-stats {
	margin: auto;
  	top: 0; left: 0; bottom: 0; right: 0;
}

@media (max-width: 991px) {
	.img-stats-mobil {
	height: 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
}


// Section order

@media (min-width: 992px) {
	.order-lg-2 {
    order: 2!important;
  }
}



// Termómetro

.main-wrapper.header-transparent {
	padding: 0 !important;
	margin: 0 !important; 
}



#termometro .title {
	font-weight: bolder;
	font-size: 44px;
	line-height: 44px;
}

#termometro .subtitle {
	font-size: 29px;
	line-height: 34px;
}

#termometro_form button {
	margin-bottom: 169px;
	background-color: #15c073;
	-webkit-border-radius:10px;
	font-size: 18px;
	color: white;
	width: 192px;
	height: 56px;
	font-weight: lighter;
	border-style: none;
}

#acta .acta_section,
.result_section {
	height: 85vh;
}

#acta .row h3.acta_question,
#result .row h3.result_text {
	font-size: 3em;
	font-weight: bold;
}

#acta .acta_button {
	font-size: 3em;
	font-weight: bolder;
	font-family: "ralewayBold", sans-serif;
}

#acta .acta_button:hover {
	color: #7c11ff;
	transition: 0.5s;
}

.ht-contact-form-two {
	max-width: 640px; }
	#contact-form input::-webkit-input-placeholder { color: #16c173; }  /* WebKit, Blink, Edge */
	#contact-form input:-moz-placeholder { color: #16c173; }  /* Mozilla Firefox 4 to 18 */
	#contact-form input::-moz-placeholder { color: #16c173; }  /* Mozilla Firefox 19+ */
	#contact-form input:-ms-input-placeholder { color: #16c173; }  /* Internet Explorer 10-11 */
	#contact-form input::-ms-input-placeholder { color: #16c173; }  /* Microsoft Edge */
	.ht-contact-form-two #contact-form input[type="submit"] {
		background-color: white;
		border: none;
		display: inline-block;
		border-radius: 1em;
		float: right;
		font-weight: 200;
		color: #16c173;
	}
	.ht-contact-form-two input {
		height: 46px;
		line-height: 24px;
		background-color: #f0f3f4;
		font-size: 15px;
		padding: 11px 20px;
		border: none;
		font-family: "Montserrat", sans-serif;
		color: #16c173;
		width: 100%; }
		.ht-contact-form-two input[type="submit"] {
			padding: 9px 25px;
			margin-bottom: 0;
			/*text-transform: uppercase;*/
			width: auto;
			border-width: 2px;
			border-style: solid;
			font-weight: 600; }
	.ht-contact-form-two textarea {
		height: 90px;
		line-height: 24px;
		background-color: #f0f3f4;
		border-radius: 5px;
		padding: 11px 20px;
		border: none;
		font-family: "Montserrat", sans-serif;
		color: #999999;
		resize: none;
		width: 100%;
		margin-bottom: 10px; }
	.ht-contact-form-two .form-messege {
		margin: 0;
		font-size: 14px;
		font-weight: 400; }


// Elipses

.elipse-lg {
    background-image: url(../img/elipse.png);
    background-repeat: no-repeat;
    background-position: 0px 4px;
    padding: 0px 10px;
    position: relative;
    left: -5px;
}

@media (max-width: 990px) {
	.elipse {
	    background-image: url(../img/elipse.png);
	    background-repeat: no-repeat;
	    background-position: 0px 2px;
	    padding: 3px 22px;
	    position: relative;
	    left: -9px;

  }
}

.elipse-lg-2 {
    background-image: url(../img/elipse2.png);
    background-repeat: no-repeat;
    background-position: 17px 0px;
    padding: 0 10px;
    position: relative;
    left: -9px;
}

@media (max-width: 990px) {
	.elipse-2 {
	    background-image: url(../img/elipse2.png);
	    background-repeat: no-repeat;
	    background-position: 8px 2px;
	    padding: 6px 12px;
	    position: relative;
	    left: -9px;
  }
}


.elipse-lg-3 {
    background-image: url(../img/elipse3.png);
    background-repeat: no-repeat;
    background-position: 7px 3px;
    padding: 6px 7px;
    position: relative;
    left: -15px;
}

@media (max-width: 990px) {
	.elipse-3 {
	    background-image: url(../img/elipse3.png);
	    background-repeat: no-repeat;
	    background-position: -1px 3px;
	    padding: 10px 10px;
	    position: relative;
	    left: -7px;
  }
}

.elipse-lg-3-en {
    background-image: url(../img/elipse3.png);
    background-repeat: no-repeat;
    background-position: 1px 3px;
    padding: 6px 10px;
    position: relative;
    left: -7px;
}

@media (max-width: 990px) {
	.elipse-3-en {
	    background-image: url(../img/elipse3.png);
	    background-repeat: no-repeat;
	    background-position: -1px 3px;
	    padding: 12px 18px;
	    position: relative;
	    left: -7px;
	}
}


// Scroll bar

.side_bar{
}
.side_bar::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
.side_bar::-webkit-scrollbar-track {
        background: $light; 
        border-radius: 15px;
    }

    /* Handle */
.side_bar::-webkit-scrollbar-thumb {
        background: $secondary; 
        border-radius: 15px;
    }

    /* Handle on hover */
.side_bar::-webkit-scrollbar-thumb:hover {
        background: none; 
    }


// swipter  



.swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    display: inline-block;
    border-radius: 100%;
    border: black solid 1.5px;
    margin: 0 10px !important;
}

.swiper-pagination-bullet-active {
    border: none !important;
    opacity: 1;
    background: #4282FD !important;
}

// Forma Cotización

.tab {
  display: none;
}

.form-control-bottom {
    border: 0;
    border-radius: 0;
    padding: 0;
    border-bottom: 1.2px solid #15bf73;
}



input[type="radio"]:checked + label:before {
  border-color: #00C851 !important;
  background-color: #00C851 !important;
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-radio [type="radio"]:checked+label:after {
  border-color: #00C851 !important;
  background-color: #00C851 !important;
}

.form-check{
	margin-bottom: 1.25rem;
}

.form-check-input {
	background-color: #fff;
	border: #00C851 solid 1.5px;
}

.form-check-input:checked {
    background-color: #00C851;
    border-color: #00C851;
}

.form-check-input[type=checkbox] {
    border-radius: 1.25em;
}

// Contact form

.jmpslZ {
    border-radius: 27px !important;
    background-color: rgb(0 0 0) !important;
    color: rgb(255 255 255) !important;
}


// Whatsapp Button

.whatsapp-button {
	position: fixed;
	z-index: 2;
	bottom: 50px;
	left: 50px;
}